import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserDetailsComponent } from './pages/browser-details/browser-details.component';


const routes: Routes = [{
  path: 'my-offer',
  loadChildren: () => import('./pages/opportunity/opportunity.module').then(m => m.OpportunityModule)
}, {
  path: '',
  loadChildren: () => import('./pages/opportunity/opportunity.module').then(m => m.OpportunityModule)
}, {
  path: 'offer-details',
  loadChildren: () => import('./pages/opportunity-details/opportunity-details.module').then(m => m.OpportunityDetailsModule)
}, {
  path: 'offerwall',
  loadChildren: () => import('./pages/create-survey/create-survey.module').then(m => m.CreateSurveyModule)
}, {
  path: 'login',
  loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
}, {
  path: 'dashboard',
  loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
}, {
  path: 'outcome',
  loadChildren: () => import('./pages/outcome/outcome.module').then(m => m.OutcomeModule)
}, {
  path: 'report',
  loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)
}, {
  path: 'browser-details/:id',
  component: BrowserDetailsComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
