import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router'

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    private _router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error.status === 401) {
          this._router.navigate(['login']);
        }
        const errorMessage = error.error.message || error.statusText;
        return throwError(errorMessage);
      })
    );
  }
}
