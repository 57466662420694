<h2 style="margin-top: 10px; font-size: 30px; text-align: center;">Summary</h2>
<div class="text-center">
    <div class="full-text">Please clear browser history/cookies before accessing the survey.</div>
</div>
<form [formGroup]="browserForm">
    <table style="margin: auto;" [cellPadding]="10" [border]="2">
        <tr>
            <th>Survey ID</th>
            <td>{{surveyId}}</td>
        </tr>
        <tr>
            <th>IP Address</th>
            <td>{{ipAddress}}</td>
        </tr>
        <tr>
            <th>Entry Date</th>
            <td>{{currentDate}}</td>
        </tr>
        <tr>
            <th>User Agent</th>
            <td>{{userAgent}}</td>
        </tr>
        <tr>
            <th>Birth Date</th>
            <td>
                <mat-form-field appearance="fill">
                    <mat-label>Select BirthDate</mat-label>
                    <input matInput [matDatepicker]="birthDate" formControlName="birthDate" readonly>
                    <mat-datepicker-toggle matIconSuffix [for]="birthDate"></mat-datepicker-toggle>
                    <mat-datepicker #birthDate disabled="false"></mat-datepicker>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <th>Gender</th>
            <td>
                <mat-form-field style="width: 250px">
                    <mat-label>Gender</mat-label>
                    <mat-select formControlName="gender">
                        <mat-option [value]="'Male'">Male</mat-option>
                        <mat-option [value]="'Female'">Female</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
    </table>
    <div style="text-align: center; margin-top: 20px;"><button (click)="sendSurvey()"
            style="background: #3ab5eb; font-size: 1em; border-width: 1px; border-style: solid; border-color: #3ab5eb; padding: 0.75em 1.5em; box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); color: #FFF;">Continue</button>
    </div>
</form>