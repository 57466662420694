import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpServiceService } from '../../services/http-service.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD-MM-YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-browser-details',
  templateUrl: './browser-details.component.html',
  styleUrls: ['./browser-details.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class BrowserDetailsComponent {
  userAgent: string = window.navigator.userAgent;
  browserName: string = '';
  surveyId: string = '';
  currentDate: string = moment().format('YYYY-MM-DD HH:mm:ss')
  ipAddress: string = '';
  surveyURL: string = '';
  browserForm: FormGroup = new FormGroup({
    birthDate: new FormControl('', [Validators.required]),
    gender: new FormControl('', [Validators.required])
  });

  constructor(
    private _router: ActivatedRoute,
    private _http: HttpServiceService,
    private _toast: ToastrService
  ) {

  }

  ngOnInit() {
    this._router.params.subscribe((res: any) => {
      this.surveyId = res.id
    })

    this._http.get('/entry-link/ip-address').subscribe((ip) => {
      this.ipAddress = ip.ip;
    })
    this.surveyURL = window.location.href.split('?')[1];
    this.browserName = this.findBrowserName()
  }

  findBrowserName() {
    let userAgent = navigator.userAgent;
    let browserName;
    
    if(userAgent.match(/chrome|chromium|crios/i)){
        browserName = "chrome";
      }else if(userAgent.match(/firefox|fxios/i)){
        browserName = "firefox";
      }  else if(userAgent.match(/safari/i)){
        browserName = "safari";
      }else if(userAgent.match(/opr\//i)){
        browserName = "opera";
      } else if(userAgent.match(/edg/i)){
        browserName = "edge";
      }else{
        browserName="No browser detection";
      }
      return browserName;
  }

  sendSurvey() {
    if(this.browserForm.valid) {
      const browserFormDetails = this.browserForm.value;
      const findAge = browserFormDetails.birthDate.diff(this.currentDate, 'years');
      
      let cookieData = document.cookie;
      if(cookieData.indexOf('sp_api_data=yes') !== -1) {
        this._toast.error('Duplicate cookie found, Please clear browser cookie');
        this._http.get('/entry-link/verify-and-send?'+this.surveyURL+'&user_agent='+this.userAgent+'&age=00&gender='+browserFormDetails.gender).subscribe((res) => {
          setTimeout(() => {
            window.location = res.link;
          }, 3000)
        });
        return ;
      } else {
        this._http.get('/entry-link/verify-and-send?'+this.surveyURL+'&user_agent='+this.userAgent+'&age='+Math.abs(findAge)+'&gender='+browserFormDetails.gender).subscribe((res) => {
          this.storeCookie();
          if(res.status) {
            window.location = res.link;
          } else {
            this._toast.error(res.message);
            setTimeout(() => {
              window.location = res.link;
            }, 3000)
          }
        })
      }
    } else {
      this._toast.error('Please select birth date and gender');
    }
  }

  storeCookie() { 
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 1000*36000;
    now.setTime(expireTime);
    console.log("now.toUTCString()", now.toUTCString());
    document.cookie = 'sp_api_data=yes;expires='+now.toUTCString()+';path=/';
  }
}
