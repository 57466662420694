import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceService {
  public url: string = environment.api;

  constructor(
    private _http: HttpClient
  ) { }

  get(path:string): Observable<any> {
    return this._http.get(this.url+path);
  }

  getWithHeader(path:string): Observable<any> {
    const auth_token = sessionStorage.getItem('access_token');
    return this._http.get(this.url+path, { headers: {'Authorization': `Bearer ${auth_token}`} });
  }

  post(path:string, data: any): Observable<any> {
    return this._http.post(this.url+path, data);
  }

  postWithHeader(path:string, data: any): Observable<any> {
    const auth_token = sessionStorage.getItem('access_token');
    return this._http.post(this.url+path, data, { headers: {'Authorization': `Bearer ${auth_token}`} });
  }
}
